import { UppyFile } from "@uppy/core";

export default async function getUploadParameters(file: UppyFile) {
  const response = await fetch(`${process.env.VITE_API_URL}/admin/uploads`, {
    method: "post",
    // Send and receive JSON.
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
    body: JSON.stringify({
      fileName: file.name,
      mimeType: file.type,
    }),
    credentials: "include",
  });
  const data: {
    url: string;
    method: string;
    mimeType: string;
  } = await response.json();
  // Return an object in the correct shape.
  return {
    method: data.method,
    url: data.url,
    headers: { "Content-Type": data.mimeType },
  };
}
