import Link from "@material-ui/core/Link";
import { ComponentProps, useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import CopyIcon from "@material-ui/icons/FilterNone";
import { IconButton } from "@material-ui/core";

export default function LinkToCopy({
  href,
  label,
  onlyCopy = false,
  ...props
}: {
  href: string;
  /**
   * The label to display for the link. If not provided, the href will be displayed.
   */
  label?: string;
  /**
   * If true, the link will not be clickable and will only copy the href
   */
  onlyCopy?: boolean;
} & ComponentProps<typeof Link>) {
  const [showCheck, setShowCheck] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (showCheck) {
      timeout = setTimeout(() => {
        setShowCheck(false);
      }, 3000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showCheck]);

  function handleClick() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(href);
    }
    setShowCheck(true);
  }

  return (
    <>
      <Link
        {...props}
        href={onlyCopy ? undefined : href}
        // If onlyCopy is true and this href is not set, the link will not have a pointer cursor by default.
        style={{ cursor: "pointer" }}
        onClick={onlyCopy ? handleClick : undefined}
        title={href}
      >
        {label || href}
      </Link>
      <IconButton onClick={handleClick} size="small" style={{ marginLeft: 4 }}>
        {showCheck ? (
          <CheckIcon fontSize="small" />
        ) : (
          <CopyIcon fontSize="small" />
        )}
      </IconButton>
    </>
  );
}
