import {
  Button,
  BooleanInput,
  DateTimeInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
  regex,
  required,
  TabbedForm,
  TextInput,
  FormTab,
  AutocompleteInput,
  SelectInput,
  maxLength,
  CheckboxGroupInput,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import debounce from "lodash/debounce";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import JSONField from "../../components/JSONField";
import FileUploader from "../../components/FileUploader";
import simpleChoices from "../../lib/form-helpers/simple-choices";
import { url } from "../../lib/validation";

import ProfileRequirements from "./ProfileRequirements";
import { profileFields } from "./ProfileRequirements/profileFields";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

const textFieldStyles = { width: "800px", maxWidth: "100%" };
const headlineStyles = { width: "auto" };

const isRequired = required();
const isUrl = url();
const isValidAbbreviation = regex(
  /^[a-zA-Z0-9]{3,5}$/,
  "Please provide 3 to 5 alphanumeric characters"
);
const isValidSlug = regex(
  /^[a-zA-Z0-9-]+$/,
  "A slug must only contain a-z, 0-9 and -"
);
const abbreviationCache: {
  id: string | null;
  value: string | null;
  result?: string;
} = {
  id: null,
  value: null,
};

const maxLength300 = maxLength(300);
const maxLength25 = maxLength(25);

async function isAbbreviationUnique(value: string, allValues: { id: string }) {
  if (
    abbreviationCache.id === allValues.id &&
    abbreviationCache.value === value
  ) {
    return abbreviationCache.result;
  }

  const response = await fetch(
    `${process.env.VITE_API_URL}/admin/calls?filter[abbreviation][eq]=${value}`,
    { credentials: "include" }
  );
  abbreviationCache.id = allValues.id;
  abbreviationCache.value = value;

  const { data } = await response.json();
  if (data && data.length > 1) {
    abbreviationCache.result = "This abbrevation is already used.";
    return abbreviationCache.result;
  }

  if (data && data.length === 1 && data[0].id === allValues.id) {
    abbreviationCache.result = undefined;
    return undefined;
  }

  if (data && data.length === 1) {
    abbreviationCache.result = "This abbrevation is already used.";
    return abbreviationCache.result;
  }
  abbreviationCache.result = undefined;
  return undefined;
}

const abbreviationValidation = [
  isRequired,
  isValidAbbreviation,
  debounce(isAbbreviationUnique, 1000, { leading: false, trailing: true }),
];

const requiredValidation = [isRequired];
const urlValidation = [isUrl];
const slugValidation = [isRequired, isValidSlug];
// @ts-ignore
const noValidation: Function[] = [];

const statusChoices = simpleChoices(["draft", "private", "public"]);
const currencyChoices = simpleChoices([
  "ARS",
  "AUD",
  "BRL",
  "CAD",
  "CHF",
  "CNY",
  "DKK",
  "EUR",
  "GBP",
  "HKD",
  "JPY",
  "MXN",
  "MYR",
  "NOK",
  "SEK",
  "SGD",
  "TRY",
  "USD",
]);

export default function Form({ isNew, ...props }: { isNew?: boolean }) {
  const classes = useStyles();

  return (
    <TabbedForm {...props}>
      <FormTab label="General">
        <TextInput disabled source="id" />
        <ReferenceInput
          label="Organisation"
          source="organisation"
          reference="organisations"
          filterToQuery={(q) => q && { name: { ilike: q } }}
          validate={requiredValidation}
        >
          <AutocompleteInput
            shouldRenderSuggestions={(val: string) => {
              return val.trim().length > 2;
            }}
            optionText="name.en"
            optionValue="id"
            autocomplete="off"
            disabled={isNew ? undefined : true}
          />
        </ReferenceInput>
        <TextInput
          source="abbreviation"
          validate={abbreviationValidation}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          label="Title"
          source="title.en"
          validate={requiredValidation}
          formClassName={classes.inlineBlock}
          validateFields={[]}
        />
        <TextInput
          source="slug"
          validate={slugValidation}
          formClassName={classes.inlineBlock}
        />
        <TextInput source="website" validate={urlValidation} />
        <BooleanInput label="Revocation allowed" source="allowsRevocation" />
        <BooleanInput
          source="useNewSubmissionWizard"
          helperText="Should this call be run with the new simplified submission wizard?"
        />
        <BooleanInput
          source="hasCoordinatorMode"
          helperText="Should this call allow coordinator mode?"
        />
        <TextInput
          multiline
          label="Description"
          source="description.en"
          style={textFieldStyles}
        />
        <FileUploader
          source="logoDefault"
          label="Logo default (SVG)"
          accept="image/*"
        />
        <TextInput
          label="Logo default (SVG)"
          source="logoDefault"
          validate={urlValidation}
        />
        <DateTimeInput
          label="Submission start"
          source="submissionStartDate"
          formClassName={classes.inlineBlock}
        />
        <DateTimeInput
          label="Submission deadline"
          source="submissionEndDate"
          formClassName={classes.inlineBlock}
        />
        <NumberInput
          label="Deadline buffer (in hours) "
          source="submissionBufferTime"
          formClassName={classes.inlineBlock}
        />
        <TextInput
          label="Submission Introduction"
          multiline
          style={textFieldStyles}
          source="submissionIntroduction.en"
        />
        <TextInput
          label="Terms & Conditions"
          multiline
          style={textFieldStyles}
          source="termsAndConditions.en"
        />
        <ArrayInput source="timeline">
          <SimpleFormIterator
            addButton={
              <Button label="Add event">
                <AddIcon />
              </Button>
            }
            removeButton={
              <Button label="Remove event">
                <RemoveIcon />
              </Button>
            }
          >
            <TextInput label="Title" source="title" />
            <DateTimeInput label="Date" source="date" />
            <BooleanInput label="Show day only" source="showDay" />
          </SimpleFormIterator>
        </ArrayInput>
        <FileUploader
          label="Submission Wizard Logo"
          source="logoSubmissionWizardBranding"
          accept="image/*"
        />
        <TextInput
          label="Submission Wizard Logo"
          source="logoSubmissionWizardBranding"
          validate={urlValidation}
        />
        <FileUploader
          label="Contest List Teaser Image"
          source="contestListTeaser"
          accept="image/*"
        />
        <TextInput
          label="Contest List Teaser Image"
          source="contestListTeaser"
          validate={urlValidation}
        />
        <SelectInput source="visibilityStatus" choices={statusChoices} />
      </FormTab>
      <FormTab label="Requirements">
        <ProfileRequirements source="profileRequirements" />
        <CheckboxGroupInput
          label="Hidden profile fields"
          source="hiddenProfileFields"
          choices={profileFields
            // Only show fields that can be hidden
            .filter((field) => field.canBeHidden)
            // Map to checkbox group input format
            .map(({ label, value }) => ({
              id: value,
              name: label,
            }))}
        />
        <JSONField
          label="Profile requirements"
          source="profileRequirements"
          height="300"
          validate={noValidation}
        />
        <TextInput
          source="maxProjects"
          helperText="Project limit on submission level (across all categories)."
        />
        <TextInput
          source="additionalRequirementsInfo"
          helperText="Currently not in use"
        />
        <BooleanInput source="forbidDuplicates" />
      </FormTab>
      <FormTab label="Payment">
        <NumberInput source="fee" formClassName={classes.inlineBlock} />
        <SelectInput
          source="currency"
          choices={currencyChoices}
          formClassName={classes.inlineBlock}
        />
        <BooleanInput
          label="Voluntary fee"
          source="isFeeVoluntary"
          helperText="Indicates if a submission fee is voluntary (donation based) or not. 'Voluntary' only works with 100% vouchers so far"
        />
        <BooleanInput source="sendStripeReceipt" />
        <BooleanInput source="showInvoice" />
        <NumberInput
          source="transactionFee"
          helperText="Percentage of total submission fee, e.g. 5%"
        />
        <NumberInput
          source="baseTransactionFee"
          helperText="Absolute fee for every payment, e.g. 2.50€"
        />
        <TextInput
          source="customFeeDescription"
          multiline
          style={textFieldStyles}
          validate={maxLength300}
          helperText="If the automatic fee description is not sufficient or incorrect, you can provide a custom description here. It will replace the automatic fee description. Max length: 300 characters."
        />
        <TextInput
          source="customFeeDescriptionShort"
          validate={maxLength25}
          helperText="Very short version of the custom fee description. Will be used in the contest grid. Max length: 25 characters."
        />
        <TextInput
          source="additionalFeeInfo"
          helperText="Currently not in use"
        />
      </FormTab>
      <FormTab label="Email">
        <FileUploader source="logoEmail" label="Logo Email" accept="image/*" />
        <TextInput source="logoEmail" validate={urlValidation} />
        <TextInput
          label="Confirmation Mail Title"
          source="confirmationMailTitle.en"
        />
        <TextInput
          label="Confirmation Mail Text"
          source="confirmationMailText.en"
          style={textFieldStyles}
          multiline
        />
      </FormTab>
      <FormTab label="Customizations">
        <Typography variant="h5" style={headlineStyles}>
          Profile
        </Typography>
        <JSONField
          label="Custom profile form fields"
          source="customProfileFields"
          height="300"
          validate={noValidation}
        />
        <Typography variant="h5" style={headlineStyles}>
          Custom Checkboxes
        </Typography>
        <TextInput
          helperText="Use Markdown for links and formatting"
          source="customNewsletterSignup1"
          style={textFieldStyles}
          multiline
        />
        <TextInput
          helperText="Use Markdown for links and formatting"
          source="customNewsletterSignup2"
          style={textFieldStyles}
          multiline
        />
        <Typography variant="h5" style={headlineStyles}>
          Custom Success Message
        </Typography>
        <TextInput
          label="Message Title"
          source="successfulSubmissionTitle.en"
        />
        <TextInput
          label="Message Content"
          source="successfulSubmissionMessage.en"
          style={textFieldStyles}
          multiline
        />
      </FormTab>
      <FormTab label="Website">
        <FileUploader
          label="Teaser Image"
          source="teaserImage"
          accept="image/*"
        />
        <TextInput
          label="Teaser Image"
          source="teaserImage"
          validate={urlValidation}
        />
        <TextInput label="Teaser Caption" source="teaserCaption" />
        <TextInput source="primaryColor" />
        <TextInput source="backgroundColor" />
        <TextInput
          label="Slogan"
          multiline
          style={textFieldStyles}
          source="slogan.en"
        />
        <TextInput label="Primary Text Title" source="primaryText.title" />
        <TextInput
          label="Primary Text Content"
          multiline
          style={textFieldStyles}
          source="primaryText.content"
        />
        <TextInput label="Secondary Text Title" source="secondaryText.title" />
        <TextInput
          label="Secondary Text Content"
          multiline
          style={textFieldStyles}
          source="secondaryText.content"
        />
        <TextInput multiline style={textFieldStyles} source="contact" />
        <TextInput multiline style={textFieldStyles} source="faqs" />
        <TextInput source="faqLink" validate={urlValidation} />
        <TextInput label="Gallery Title" source="gallery.title" />
        <ArrayInput label="Gallery Sections" source="gallery.sections">
          <SimpleFormIterator
            addButton={
              <Button label="Add gallery">
                <AddIcon />
              </Button>
            }
            removeButton={
              <Button label="Remove gallery">
                <RemoveIcon />
              </Button>
            }
          >
            <TextInput label="Title" source="title" />
            <TextInput label="Caption" source="caption" />
            <ArrayInput label="Images" source="images">
              <SimpleFormIterator
                addButton={
                  <Button label="Add image">
                    <AddIcon />
                  </Button>
                }
                removeButton={
                  <Button label="Remove image">
                    <RemoveIcon />
                  </Button>
                }
              >
                <TextInput label="Title" source="title" />
                <FileUploader source="image" label="Image" accept="image/*" />
                <TextInput label="Image" source="image" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
        <FileUploader source="video" label="Video" accept="video/*" />
        <TextInput source="video" validate={urlValidation} />
        <TextInput source="videoTitle" />
      </FormTab>
    </TabbedForm>
  );
}
