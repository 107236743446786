import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showNotification } from "react-admin";
import ConfirmedAction from "../../../components/ConfirmedAction";
import jsonapiClient from "../../../lib/data-provider";

const dataProvider = jsonapiClient(`${process.env.VITE_API_URL}/app-content`);

const RevokeSubmission = ({ resource, showNotification }) => (
  <ConfirmedAction
    action={(close) => () =>
      dataProvider
        .update("submissions", {
          id: resource.id,
          data: { submitted: false },
        })
        .then(() => {
          showNotification("Submission revoked");
          close();
        })
        .catch((e) => {
          console.error(e);
          showNotification("Error: submission could not be revoked", "warning");
        })}
    buttonLabel="Revoke and refund"
    title="Please confirm"
    body="Are you sure, that you want to revoke this submission and refund any paid submission fees (if applicable)?"
  />
);

RevokeSubmission.propTypes = {
  resource: PropTypes.object,
  push: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
};

export default connect(null, {
  showNotification,
})(RevokeSubmission);
