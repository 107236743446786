import { Edit, EditProps, Record, TopToolbar, ShowButton } from "react-admin";
import Form from "./Form";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
const Title = () => <span>Edit call info</span>;

const EditActions = ({ data }: { data?: Record }) => (
  <TopToolbar>
    {data && (
      <ShowButton
        to={`/calls/${data.call}/show`}
        label="Back to Call"
        icon={<ArrowBackIcon />}
      />
    )}
  </TopToolbar>
);

const redirect = (_basePath: string, _id: string, data: Record) =>
  `/calls/${data.call}/show`;

export default function EditForm(props: EditProps) {
  return (
    <Edit
      title={<Title />}
      undoable={false}
      actions={<EditActions />}
      {...props}
    >
      <Form redirect={redirect} disableCallInput />
    </Edit>
  );
}
