import React from "react";
import {
  DateField,
  CloneButton,
  BooleanField,
  Datagrid,
  EditButton,
  ShowButton,
  List,
  TextField,
  FunctionField,
  Filter,
  TextInput,
  SelectInput,
  BooleanInput,
  useRecordContext,
} from "react-admin";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";

import adSlots from "./utils/ad-slots.js";

function formatArrayFieldToString(value) {
  let formattedStr = value;
  if (value?.length > 0) {
    formattedStr = value.slice(0, 2).join(", ");
  }
  if (value?.length > 2) {
    formattedStr += ` + ${value.length - 2} other`;
  }
  return formattedStr;
}

const Filters = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Ad Slot"
      source="adSlot.eq"
      choices={adSlots}
      alwaysOn
    />
    <TextInput label="Campaign" source="campaign.ilike" alwaysOn />
    <BooleanInput label="Active" source="active.eq" alwaysOn />
  </Filter>
);

const PreviewLink = ({ source }) => {
  const record = useRecordContext();
  const previewURL = `${process.env.VITE_PUBLIC_ADS_APP_URL}/preview?id=${record[source]}`;
  return (
    <Tooltip title="View ad preview" placement="top">
      <Button
        color="primary"
        onClick={() => window.open(previewURL, "_blank").focus()}
      >
        <LinkIcon style={{ marginRight: "4px" }} />
        Preview
      </Button>
    </Tooltip>
  );
};

export default function ListPage(props) {
  return (
    <List filters={<Filters />} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="adSlot" />
        <TextField source="campaign" />
        <TextField source="priority" />
        <TextField source="weight" />
        <DateField source="from" />
        <DateField source="to" />
        <FunctionField
          source="geoInclude"
          render={({ geoInclude }) => formatArrayFieldToString(geoInclude)}
        />
        <FunctionField
          source="geoExclude"
          render={({ geoExclude }) => formatArrayFieldToString(geoExclude)}
        />
        <BooleanField source="active" />
        <PreviewLink source="id" label="Preview" sortable={false} />
        <ShowButton />
        <EditButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
}
