import React, { useMemo } from "react";
import {
  DateInput,
  DateField,
  BooleanInput,
  required,
  SimpleForm,
  TextInput,
  SelectInput,
  AutocompleteArrayInput,
  NumberInput,
  FunctionField,
  FormDataConsumer,
} from "react-admin";

import Typography from "@material-ui/core/Typography";

import AdContentInput from "./components/AdContentInput";
import LinkToCopy from "./components/LinkToCopy";

import countryList from "country-list";
import countriesByContinent from "./utils/countriesByContinent.json";

import adSlots from "./utils/ad-slots.js";

const isRequired = required();

export default function Form({ isNew, ...props }) {
  const countries = useMemo(
    () => countryList.getData().map(({ name, code }) => ({ id: code, name })),
    []
  );

  const countriesByContinentName = useMemo(
    () =>
      Object.entries(countriesByContinent).map(([k, v]) => ({
        id: v,
        name: k,
      })),
    []
  );

  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      {!isNew && (
        <FunctionField
          label="Click URL"
          helperText="click to copy"
          source="clickUrl"
          render={({ clickUrl }) => <LinkToCopy href={clickUrl} onlyCopy />}
        />
      )}
      {!isNew && (
        <FunctionField
          label="Tracking Pixel"
          source="impressionUrl"
          render={({ impressionUrl }) => (
            <LinkToCopy href={`${impressionUrl}&gif=true`} onlyCopy />
          )}
        />
      )}
      <BooleanInput source="active" />
      <SelectInput validate={[isRequired]} source="adSlot" choices={adSlots} />
      <TextInput source="campaign" validate={[isRequired]} />
      <TextInput source="name" validate={[isRequired]} />
      <TextInput source="url" validate={[isRequired]} />

      <FormDataConsumer>
        {({ formData }) => (
          <>
            <div>
              <DateInput
                source="from"
                validate={[isRequired]}
                helperText={
                  formData.adSlot === "Dedicated Newsletter"
                    ? "Set any date for the Dedicated Newsletter"
                    : ""
                }
              />
            </div>
            <DateInput
              source="to"
              validate={[isRequired]}
              helperText={
                formData.adSlot === "Dedicated Newsletter"
                  ? "Set any date for the Dedicated Newsletter"
                  : ""
              }
            />
            <AutocompleteArrayInput
              source={"geoInclude"}
              choices={[...countriesByContinentName, ...countries]}
              disabled={formData.adSlot === "Dedicated Newsletter"}
              helperText={
                formData.adSlot === "Dedicated Newsletter"
                  ? "Not relevant for Dedicated Newsletter"
                  : ""
              }
            />
            <AutocompleteArrayInput
              source={"geoExclude"}
              choices={[...countriesByContinentName, ...countries]}
              disabled={formData.adSlot === "Dedicated Newsletter"}
              helperText={
                formData.adSlot === "Dedicated Newsletter"
                  ? "Not relevant for Dedicated Newsletter"
                  : ""
              }
            />
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginBottom: "16px" }}
            >
              <span role="img" aria-label="warning">
                ⚠️
              </span>{" "}
              Geo-excludes override geo-includes field. (e.g. Adding "US" in
              both fields will exclude US country for serving ad.)
            </Typography>
            <SelectInput
              validate={[isRequired]}
              source="priority"
              choices={[
                { id: 1, name: "1 (inhouse)" },
                { id: 2, name: "2 (affiliate)" },
                { id: 3, name: "3" },
                { id: 4, name: "4 (default)" },
                { id: 5, name: "5" },
              ]}
              defaultValue={4}
              disabled={formData.adSlot === "Dedicated Newsletter"}
              helperText={
                formData.adSlot === "Dedicated Newsletter"
                  ? "Not relevant for Dedicated Newsletter"
                  : ""
              }
            />
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginBottom: "16px" }}
            >
              <span role="img" aria-label="warning">
                ⚠️
              </span>{" "}
              Higher priority will be served before lower priority.
            </Typography>
            <NumberInput
              source={"weight"}
              min={1}
              disabled={formData.adSlot === "Dedicated Newsletter"}
              helperText={
                formData.adSlot === "Dedicated Newsletter"
                  ? "Not relevant for Dedicated Newsletter"
                  : ""
              }
            />
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginBottom: "16px" }}
            >
              <span role="img" aria-label="warning">
                ⚠️
              </span>{" "}
              {`Higher weight will be served more often than lower weight (e.g. 2
              vs 1 -> 2 will be delivered twice as much as 1)`}
            </Typography>
          </>
        )}
      </FormDataConsumer>

      <AdContentInput source="content" />

      {!isNew && <DateField source="createdAt" />}
      {!isNew && <DateField source="editedAt" />}
    </SimpleForm>
  );
}
