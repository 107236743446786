import React, { Component } from "react";
import KeyIcon from "@material-ui/icons/VpnKey";
import AdIcon from "@material-ui/icons/LocalOffer";
import { Admin, Resource } from "react-admin";
import englishMessages from "ra-language-english";
import jsonapiClient from "./lib/data-provider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import authProvider from "./authProvider";
import * as ads from "./resources/ads";
import * as calls from "./resources/calls";
import * as callInfos from "./resources/call-infos";
import * as callPages from "./resources/call-pages";
import * as callPreconditions from "./resources/call-preconditions";
import * as callUsers from "./resources/call-users";
import * as callWebhooks from "./resources/call-webhooks";
import * as featureFlags from "./resources/feature-flags";
import * as invitations from "./resources/invitations";
import * as submissions from "./resources/submissions";
import * as submissionCategories from "./resources/submission-categories";
import * as organisations from "./resources/organisations";
import * as picterApiKeys from "./resources/picter-api-keys";
import * as productFeatures from "./resources/product-features";
import * as plans from "./resources/plans";
import * as planProductFeatures from "./resources/plan-product-features";
import * as organisationProductFeatures from "./resources/organisation-product-features";
import * as users from "./resources/users";
import * as vouchers from "./resources/vouchers";
import * as wsSpaces from "./resources/ws-spaces";
import * as wsSpaceUsers from "./resources/ws-space-users";
import customRoutes from "./custom-routes";

const dataProvider = jsonapiClient(`${import.meta.env.VITE_API_URL}/admin`);
const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
  allowMissing: true,
  // @ts-ignore
  onMissingKey: (key, _, __) => key,
});

class App extends Component {
  render() {
    return (
      <Admin
        authProvider={authProvider}
        // @ts-ignore
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
      >
        <Resource name="ads" {...ads} icon={AdIcon} />
        <Resource name="calls" {...calls} />
        <Resource name="submission-categories" {...submissionCategories} />
        <Resource name="call-infos" {...callInfos} />
        <Resource name="call-pages" {...callPages} />
        <Resource name="call-preconditions" {...callPreconditions} />
        <Resource name="call-webhooks" {...callWebhooks} />
        <Resource name="call-users" {...callUsers} />
        <Resource name="feature-flags" {...featureFlags} />
        <Resource name="organisations" {...organisations} />
        <Resource
          name="picter-api-keys"
          {...picterApiKeys}
          icon={KeyIcon}
          options={{ label: "API keys" }}
        />
        <Resource name="plans" {...plans} />
        <Resource name="product-features" {...productFeatures} />
        <Resource name="plan-product-features" {...planProductFeatures} />
        <Resource
          name="organisation-product-features"
          {...organisationProductFeatures}
        />
        <Resource name="submissions" {...submissions} />
        <Resource name="users" list={users.list} edit={users.edit} />
        <Resource name="vouchers" {...vouchers} />
        <Resource name="invitations" {...invitations} />
        <Resource
          options={{ label: "WS Spaces" }}
          name="ws-spaces"
          {...wsSpaces}
        />
        <Resource name="ws-space-users" {...wsSpaceUsers} />
      </Admin>
    );
  }
}

export default App;
