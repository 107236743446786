import { AuthProvider } from "react-admin";
const authProvider = {
  login({ username, password }) {
    const request = new Request(`${process.env.VITE_API_URL}/authenticate`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        console.log(response);
        localStorage.setItem("user", response);
      });
  },
  logout() {
    const request = new Request(`${process.env.VITE_API_URL}/authenticate`, {
      method: "DELETE",
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request).then((response) => {
      if (
        response.status < 200 ||
        (response.status >= 300 && response.status !== 401)
      ) {
        throw new Error(response.statusText);
      }
      localStorage.removeItem("user");
      return Promise.resolve();
    });
  },
  checkError(error) {
    const status = error.status;
    console.log("auth error");
    if (status === 401 || status === 403) {
      localStorage.removeItem("user");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  async checkAuth(params) {
    console.log("checkAuth");
    if (localStorage.getItem("user")) {
      return Promise.resolve();
    }
    const request = new Request(`${process.env.VITE_API_URL}/user`, {
      method: "GET",
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      return Promise.reject();
    }
    const user = await response.json();
    localStorage.setItem("user", user);
    return Promise.resolve();
  },
  getIdentity: () => {
    console.log("getIdentity");
    const user = (
      localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user") as string)
        : null
    ) as {
      id: string;
      publicName: string;
    } | null;
    if (user) {
      return Promise.resolve({
        id: user.id,
        fullName: user.publicName,
      });
    }
    return Promise.resolve();
  },
  // authorization
  getPermissions: (params) => {
    console.log("getPermission");
    return Promise.resolve();
  },
} as AuthProvider;

export default authProvider;
