import { Edit, TopToolbar, ShowButton } from "react-admin";
import Form from "./Form";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
const Title = ({ record }) => (
  <span>Edit {record ? `"${record.label.en}"` : ""}</span>
);

const EditActions = ({ data }) => (
  <TopToolbar>
    {data && (
      <ShowButton
        to={`/calls/${data.call}/show`}
        label="Back to Call"
        icon={<ArrowBackIcon />}
      />
    )}
  </TopToolbar>
);

export default function EditForm(props) {
  return (
    <Edit
      title={<Title />}
      undoable={false}
      actions={<EditActions />}
      {...props}
    >
      <Form redirect={false} disableCallInput />
    </Edit>
  );
}
